<template>
    <div>
        <el-dialog :title="title" :visible.sync="open" width="700px" @close="commitOnClose">
            <el-form ref="saveOrEditForm" :model="saveData" label-width="150px" :rules="rules">
                <el-form-item label="业务类型" prop="type">
                    <el-select v-model="saveData.type"
                           placeholder="业务类型" clearable size="small" style="width: 240px">
                           <el-option v-for="(value, key) in dicts.BLACK_WHITE_TYPE" :label="value" :value="key" :key="key"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="配置对象" prop="userRole">
                    <el-select v-model="saveData.userRole"
                           placeholder="业务类型" clearable size="small" style="width: 240px">
                        <el-option label="机构" value="ORG"/>
                        <el-option label="代理商" value="AGENT"/>
                        <el-option label="商户" value="MER"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="配置对象编号" prop="agentNo">
                    <el-input v-model="saveData.agentNo" type="textarea"
                              placeholder="如有多个，请换行输入编号"/>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="saveData.remark" type="textarea"
                              placeholder="请输入备注"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitCheck">确定新增</el-button>
                <el-button type="primary" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {SpecialApi} from '@/api';
    export default {
        name: "CustomForm",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            isAdd: {
                type: Boolean,
                default: true,
            },
            selectRow: {
                type: Object,
                default: function () {
                    return {}
                },
            },
            onFresh: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                title: '',
                saveData: {},
                open: false,
                rules:{
                    type:[{ required: true, message: '请选择业务类型', trigger: 'change' }],
                    userRole:[{ required: true, message: '请选择配置对象', trigger: 'change' }],
                    agentNo:[{ required: true, message: '请输入配置对象编号', trigger: 'blur' }],
                }
            }
        },
        watch: {
            onFresh: function (value) {
                this.open = value;
                if (value) {
                    this.resetData();
                }
            }
        },
        mounted() {
        },
        methods: {
            submitCheck(){
                this.$refs.saveOrEditForm.validate((valid) => {
                    if (valid) {
                        this.submitForm()
                    } else {
                        return false;
                    }
                });
            },
            async resetData() {
                if (this.isAdd){
                    this.title = "添加黑白名单配置";
                    this.saveData = {};
                }else {
                    this.saveData = this.selectRow;
                    this.isAdd = false;
                    this.title = "修改黑白名单配置";
                }
            },
            async submitForm() {
                this.saveData.agentNo = this.saveData.agentNo.split('\n')
                let result = (this.isAdd) ?
                        (await SpecialApi.blackWhiteConfig.save(this.saveData)) :
                        (await SpecialApi.blackWhiteConfig.edit(this.saveData.id, this.saveData));
                if (result.success) {
                    this.Message.success(this.isAdd ? '添加成功' : '修改成功');
                    this.commitOnClose();
                }
            },
            cancel() {
                this.saveData = {};
                this.commitOnClose();
            },
            commitOnClose() {
                this.$emit('on-close');
            }
        }
    };
</script>

<style scoped>

</style>
